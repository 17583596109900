/*
 * This file is part of "artools".
 *    Copyright 2009-2011, arNuméral
 *    Author : Yoran Brault
 *    eMail  : yoran.brault@bad_arnumeral.fr (remove bad_ before sending an email)
 *    Site   : http://www.arnumeral.fr
 *
 * "artools" is free software; you can redistribute it and/or
 * modify it under the terms of the GNU General Public License as
 * published by the Free Software Foundation; either version 2.1 of
 * the License, or (at your option) any later version.
 *
 * "artools" is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the GNU
 * General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with "artools"; if not, write to the Free
 * Software Foundation, Inc., 51 Franklin St, Fifth Floor, Boston, MA
 * 02110-1301 USA, or see the FSF site: http://www.fsf.org.
 */

/**
 * Construction d'un plugin jquery.
 */
function jQueryPlugin(pluginName, classInstance) {
  (function(jQuery) {
    var plugin = function(userSettings) {
      if (this.length > 0 ) {
        if (this.length > 1) {
          return this.each(function() {
            eval("jQuery(this)."+pluginName+"(userSettings)");
          });
        }
        if(classInstance.setup && !classInstance.isSetup) {
          classInstance.setup();
          classInstance.isSetup=true;
        }
        var pluginInstance = new classInstance();
        jQuery.extend(true, pluginInstance, userSettings);
        pluginInstance.element = this[0];
        pluginInstance.initialize();
      }
      return this;
    }
    jQuery.fn[pluginName] = plugin;
    jQuery[pluginName] = classInstance;
  })(jQuery);
}

function isInIframe() {
  return (window.location != window.parent.location) ? true : false;
}
function waitDialogClosed(callback) {
  console.log(callback);
  if (Overlay.isVisible()) {
    console.log('wait...');
    window.setTimeout(function() {
      console.log(callback);
      waitDialogClosed(callback); }, 1000);
  } else {
    console.log('okt...');
    callback();
  }
}

function openDialog(settings) {
  var popup =  '<div class="ui-window">';
      popup += '<div class="caption">'+settings.title+'<span class="close"></span></div>';
      popup += '<div class="ui-loading big"></div>';
      popup += '<iframe src="'+settings.url+'"></iframe>';
      popup += '</div>';

  popup = jQuery(popup)
    .css(settings.style)
    .appendTo('body')
    .modal({
      zindex:10000,
      onClose:function() {
        jQuery('.ui-window').remove();
      }
    });
  var captionHeight = jQuery('.caption', popup).height();
  var popupHeight = popup.height();
  var popupWidth = popup.width();

  jQuery('iframe', popup)
    .css({
      'margin-left':popupWidth,
      height  : popupHeight-captionHeight,
      width   : popupWidth})
    .load(function() {
      jQuery('.ui-loading', popup).remove();
      jQuery('iframe', popup).css({'margin-left':0});
    });

  jQuery('.close', popup).click(function() {
    Modal.close();
  });
}

function closeDialog() {
  Modal.close();
}

/**
 * Décodate d'une chaîne UTF8.
 * @param String utftext la chaîne à décoder
 * @return String la chaîne décodée.
 */
function utfDecode(utftext) {
  var string = "";
  var i = 0;
  var c = 0, c3 = 0, c2 = 0;

  while (i < utftext.length) {
    c = utftext.charCodeAt(i);
    if (c < 128) {
      string += String.fromCharCode(c);
      i++;
    } else if ((c > 191) && (c < 224)) {
      c2 = utftext.charCodeAt(i + 1);
      string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
      i += 2;
    } else {
      c2 = utftext.charCodeAt(i + 1);
      c3 = utftext.charCodeAt(i + 2);
      string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6)
          | (c3 & 63));
      i += 3;
    }

  }

  return string;
}

/**
 * parser une URL.
 * @param String uri l'uri à parser
 * @return Array un tableau contenant les éléments parsés
 */
function parseUri(uri) {
  var keys = [ "source", "protocol", "authority", "userInfo", "user",
      "password", "host", "port", "relative", "path", "directory", "file",
      "query", "anchor" ];
  var strictParser = /^(?:([^:\/?#]+):)?(?:\/\/((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?))?((((?:[^?#\/]*\/)*)([^?#]*))(?:\?([^#]*))?(?:#(.*))?)/;
  var matches = strictParser.exec(uri);
  var result = {};

  for (var iKey = 14; iKey >= 0; iKey--) {
    result[keys[iKey]] = matches[iKey] || "";
  }
  result['queryString'] = {};
  result['query'].replace(/(?:^|&)([^&=]*)=?([^&]*)/g, function(a, b, c) {
    if (b)
    result['queryString'][b] = c;
  });

  return result;
}

/**
 * Fontion permettant d'imploser une URI parsée.
 * @param Array uri le tableau d'éléments (fourni par parseUri)
 * @return String l'url complète
 */
function implodeUri(uri) {
  var first = true;
  var result = '';
  if (uri['protocol'] != '') {
    result += uri['protocol'] + "://";
  }
  if (uri['user'] != '') {
    result += uri['user'];
    if (uri['password'] != '') {
      result += ":" + uri['password'];
    }
    result += "@";
  }
  result += uri['path'];
  for ( var queryKey in uri['queryString']) {
    if (!first) {
      result += "&";
    } else {
      result += "?";
    }
    first = false;
    result += queryKey + "=" + uri['queryString'][queryKey];
  }
  return result;
}

function getPageScrollPosition() {
  var yScroll;
  if (self.pageYOffset) {
    yScroll = self.pageYOffset;
  } else if (document.documentElement && document.documentElement.scrollTop) {
    yScroll = document.documentElement.scrollTop;
  } else if (document.body) {
    yScroll = document.body.scrollTop;
  }
  return yScroll
}

function queryGet(name, defaultValue) {
  name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
  var regexS = "[\\?&]" + name + "=([^&#]*)";
  var regex = new RegExp(regexS);
  var results = regex.exec(window.location.search);
  if(results == null)
    return defaultValue;
  else
    return decodeURIComponent(results[1].replace(/\+/g, " "));
}

if (typeof console === "undefined"){
  console={};
  console.log = function(){
      return;
  }
}

function insertAtCursor(myField, myValue) {
  if (document.selection) {
    myField.focus();
    var sel = document.selection.createRange();
    sel.text = myValue;
  }
  else if (myField.selectionStart || myField.selectionStart == '0') {
    var startPos = myField.selectionStart;
    var endPos = myField.selectionEnd;
    myField.value = myField.value.substring(0, startPos)
      + myValue
      + myField.value.substring(endPos, myField.value.length);
  } else {
    myField.value += myValue;
  }
}

function getTextSelection() {
  if (window.getSelection) {
    return window.getSelection();
  } else if (document.getSelection) {
    return document.getSelection();
  } else if (document.selection) {
    return document.selection.createRange().text;
  }
  return '';
}


//function setup() {
  //function is(browser) {
    //if(u.indexOf(browser) !=-1) return true;
  //}
  //var $body=jQuery('body');
	//var u = navigator.userAgent.toLowerCase();

  //var tag = '';
	//if(is("win")) tag = "win";
	//else if(is("mac")) tag = "mac";
	//else if(is("linux") || is("x11")) tag = "linux";
  //if (tag!='') {
    //$body.addClass(tag);
  //}
  //tag='';

	//if(is("msie")) tag = "msie";
	//else if(is("firefox")) tag = "mozilla";
	//else if(is("opera")) tag = "opera";
	//else if(is("safari")) tag = "webkit";
	//else if(is("chrome")) tag = "webkit";
  //if (tag!='') {
    //$body.addClass(tag);
  //}
//}

function json(url, data, callback) {
  if(typeof data == 'function') {
    callback = data;
    data = {};
  }
  jQuery.ajax({
    dataType: "json",
    type: "POST",
    data: data,
    url: '/' + url + '?ts='+new Date().getTime(),
    success: function(data) {
      if (typeof callback != 'undefined') {
        callback(data, true);
      }
    }
  });
}

jQuery(function(setup) { setup(); })
window.Artools = module.exports = {
  jQueryPlugin          : jQueryPlugin,
  isInIframe            : isInIframe,
  waitDialogClosed      : waitDialogClosed,
  openDialog            : openDialog,
  closeDialog           : closeDialog,
  utfDecode             : utfDecode,
  parseUri              : parseUri,
  implodeUri            : implodeUri,
  getPageScrollPosition : getPageScrollPosition,
  queryGet              : queryGet,
  insertAtCursor        : insertAtCursor,
  getTextSelection      : getTextSelection,
  json                  : json
}
