jQuery(function() {
  var zoomable = jQuery('.zoom');
  if (zoomable.length) {
    zoomable.colorbox({
      maxWidth:'80%',
    })
  }
  var scrollable = jQuery('.zoom-scroll');
  if (scrollable.length) {
    scrollable.colorbox({
      maxWidth:'80%',
      scrolling: true,
      initialWidth:'550px',
      initialHeight:'350px',
      onComplete: function() {
        var windowHeight = $(window).height();
        jQuery("#cboxContent").css({height:0.8*windowHeight});
        jQuery("#cboxLoadedContent").css({height:0.8*windowHeight});
      }
    })
  }
});

