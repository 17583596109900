/*
 * This file is part of "artools".
 *    Copyright 2009-2011, arNuméral
 *    Author : Yoran Brault
 *    eMail  : yoran.brault@bad_arnumeral.fr (remove bad_ before sending an email)
 *    Site   : http://www.arnumeral.fr
 *
 * "artools" is free software; you can redistribute it and/or
 * modify it under the terms of the GNU General Public License as
 * published by the Free Software Foundation; either version 2.1 of
 * the License, or (at your option) any later version.
 *
 * "artools" is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the GNU
 * General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with "artools"; if not, write to the Free
 * Software Foundation, Inc., 51 Franklin St, Fifth Floor, Boston, MA
 * 02110-1301 USA, or see the FSF site: http://www.fsf.org.
 */

function Overlay() {}
Overlay.show = function() {
  Overlay.close();
  jQuery('body')
    .append('<div id="artools-overlay"></div>')
    .css({overflow:'hidden'});
}

Overlay.close = function() {
  jQuery("#artools-overlay").remove();
  jQuery('body').css({overflow: 'auto'});
}

Overlay.isVisible = function() {
  return jQuery("#artools-overlay").length != 0;
}
window.Overlay = Overlay;

