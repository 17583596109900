function Center(element, userSettings) {
  this.kind = 'both';
  this.animate = true;

  // Réglages utilisateur
  jQuery.extend(true, this, userSettings);

  // Positionnement absolu
  var d = jQuery(element);
  var p = d.offset();
  d.css({
    position : 'fixed',
    top : p.top + 'px',
    left : p.left + 'px'
  });

  // Position cible
  var target = {};
  if (this.kind == 'both' || this.kind == 'horizontal') {
    target.left = ((jQuery(window).width() - d.outerWidth()) / 2) + jQuery(window).scrollLeft() + "px";
  }
  if (this.kind == 'both' || this.kind == 'vertical') {
    target.top = (((jQuery(window).height() - d.outerHeight()) / 2) ) + "px";
  }
  if (this.kind != 'none') {
    if (this.animate) {
      d.animate(target);
    } else {
      d.css(target);
    }
  }
}

(function($) {
  $.fn.center = function(userSettings) {
    if (this.length > 1) {
      return this.each(function() {
        jQuery(this).center(userSettings);
      });
    }
    new Center(this.get(0), userSettings);
    return this;
  };
})(jQuery);


