function Modal() {
  this.center = 'both';
  this.animate = true;
  this.onClose = null;
  this.zindex = 40;
}

Modal.close = function() {
  Overlay.close();
  if (Modal.current) {
    Modal.current.element.removeAttribute('style');
    if (Modal.current.onClose) {
      Modal.current.onClose(this);
    }
  }
  Modal.current = null;
}

Modal.prototype.initialize = function() {
  Modal.current = this;
  Overlay.show();
  jQuery(Modal.current.element).center({
    kind : this.center,
    animate : this.animate
  });
  jQuery(Modal.current.element).css({
    display : 'block',
    zIndex : this.zindex
  });
}

window.Modal = Modal;
Artools.jQueryPlugin('modal', Modal);

