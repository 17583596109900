// Module
var Slaves = (function() {
  // Constructeur
  function Slaves() {
  }

  Slaves.prototype.initialize = function() {
    var self = this;
    this.animate = false;
    if (this.master) {
      this.$master = jQuery(this.master, this.element);
      this.$slaves = jQuery(this.slaves, this.element);
    } else {
      this.$master = jQuery(this.element);
      this.$slaves = jQuery(this.slaves);
    }
    this.$master.change(function() { self.update() });
    this.$master.change();
    this.animate = true;
  }

  Slaves.prototype.update = function() {
    var isVisible = this.visibility(this.$master[0]);
    if (!this.animate) {
      this.$slaves.css({display: isVisible?'block':'none'});
    } else {
      if (isVisible) {
        this.$slaves.slideDown();
      } else {
        this.$slaves.slideUp();
      }
    }
  }

  return Slaves;
})();
Artools.jQueryPlugin('slaves', Slaves);


// Module
var Stable = (function() {
  // Constructeur
  function Stable() {
  }

  Stable.setup = function() {
    var position = jQuery.cookie('scroll_position');
    if (position) {
      console.log('stabilisation');
      window.scroll(0, position);
    }
    jQuery.cookie('scroll_position', null);
  }

  Stable.prototype.initialize = function() {
    jQuery(this.element).click(function() {
      jQuery.cookie('scroll_position', Artools.getPageScrollPosition());
    });
  }

  return Stable;
})();
Artools.jQueryPlugin('stable', Stable);



