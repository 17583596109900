/*
 * This file is part of "artools".
 *    Copyright 2009-2011, arNuméral
 *    Author : Yoran Brault
 *    eMail  : yoran.brault@bad_arnumeral.fr (remove bad_ before sending an email)
 *    Site   : http://www.arnumeral.fr
 *
 * "artools" is free software; you can redistribute it and/or
 * modify it under the terms of the GNU General Public License as
 * published by the Free Software Foundation; either version 2.1 of
 * the License, or (at your option) any later version.
 *
 * "artools" is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the GNU
 * General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with "artools"; if not, write to the Free
 * Software Foundation, Inc., 51 Franklin St, Fifth Floor, Boston, MA
 * 02110-1301 USA, or see the FSF site: http://www.fsf.org.
 */

/**
 * Plugin jquery pour transformer une arborescence de liens de type ul/li/ul en
 * menu popup.
 *
 * @param object userSettings
 *    - onClose : fonction à invoquer lors de la fermeture de la popup.
 *    - align   : left ou right.
 *    - onClick : fonction appelée lorsque l'utilisateur clique sur
 *                un item (vient replacer l'éventuel href du lien)
 *    - x, y    : les coordonnées initiales du menu.
 */
var Popup = window.Popup = (function() {
    Popup.current = null;

    function Popup() {
       this.onClose  = function() {};
       this.align    = 'left';
       this.cssClass = '';
       this.xOffset  = function() { return 0; };
       this.yOffset  = function() { return 0; };
       this.width    = null;
    }

    // Réinitialisation d'une popup éventuellement ouverte
    Popup.reset = function() {
      if (Popup.current) {
        jQuery("#artools-menu-popup").remove();
        Popup.current.onClose();
      }
    }

    // fermeture d'un menu pré-existant
    Popup.close = function() {
      if (Popup.current) {
        Popup.reset();
        Popup.current = null;
      }
    }

    Popup.isVisible = function () {
      return jQuery("#artools-menu-popup").length!=0;
    }

    Popup.prototype.initialize = function() {
      // Ménage
      Popup.reset();

      // Création du menu global
      Popup.popup = jQuery('"<div id="artools-menu-popup" class="'+this.cssClass+'"></div>');
      jQuery(this.element).clone().appendTo(Popup.popup);
      jQuery('ul', Popup.popup).addClass('ui-popup');

      // Suppression des éléments désactivés
      jQuery(".disabled", Popup.popup).each(function() {
        jQuery(this).parent().remove();
      });

      // Ajout au body
      Popup.popup.appendTo("body");
      Popup.popupRoot = jQuery(">ul", Popup.popup);
      Popup.current = this;

      // Affichage/Masquage du menu complet
      Popup.popupRoot.hover(
        function() {
          clearTimeout(Popup.popupRoot.timer);
        },
        function () {
          Popup.popupRoot.timer = setTimeout(function() {
            //Popup.close();
          },500);
        });

        // Positionnement du menu principal
        var x = this.x;
        if (this.width) {
          Popup.popupRoot.css({width:this.width});
        }
        var width = Popup.popupRoot.innerWidth();
        var bodyWidth = jQuery('body').width();
        var align = this.align;
        if (align=='left' && (x+width) > bodyWidth) {
          align = 'right';
        }
        switch (align) {
          case 'right':
            x = x - width + this.xOffset(align);
            break;
          case 'left':
            x = x + this.xOffset(align);
            break;
        }
        var y = this.y+this.yOffset(align);
        Popup.popupRoot.css({position:'absolute', left:x, top:y});
        Popup.popupRoot.css({display : "block"});

      // Intégration des clicks
      var self = this;
      jQuery("li a", Popup.popupRoot).each(function() {
        var $link = jQuery(this);
        var $ul = jQuery('>ul', $link.parent());
        if ($ul.length) {
          $ul.parent().addClass("has-children");
          $link.hover(function() {
            $link.timer = window.setTimeout(function() {
            var wasVisible = $ul.css('display')!='none';
            jQuery("ul", $ul.parent().parent()).css({display:"none"});
            if (!wasVisible) {
              var position = $ul.parent().position();
              position.left+=$ul.parent().innerWidth();
              if ((x+position.left+$ul.innerWidth())>bodyWidth) {
                position.left = -$ul.innerWidth();
              }

              $ul.css({left:position.left,top:position.top});
                $ul.css({display:"block"});
            }
            return false;
          }, 200);},function() {
           window.clearTimeout($link.timer);
          } ).attr('href', 'javascript:;');
        }
        if (!$link.hasClass('no-click')) {
          $link.click(function(){
            Popup.close();
            if (self.onClick) {
              self.onClick(this);
              return false;
            }
          });
        }
      });
    }
  return Popup;
})();
Artools.jQueryPlugin('menuPopup', Popup);


