function MultiSelectPopup() {
  this.minSelectableLevel = -1;
  this.maxSelectableLevel = 1000;
  this.maxItem = 0;
  this.maxItemsMessage = 'You reach maximum item you can select';
  this.label = 'Click here to add a new item';
}

MultiSelectPopup.prototype.itemSelection = function(link) {
  var $link = jQuery(link);
  $link.parent().hide();
  var text='';
  jQuery($link.parents('li[oid]').get().reverse()).each(function() {
    if (text!='') {
      text += " » ";
    }
    text+=jQuery('>a',this).text();
  });
  var value = $link.parent().attr('oid');
  if (!this.isMultiple) {
    jQuery(".selected li", this.$wrapper).remove();
    jQuery("option", this.$select).attr('selected', false);
  }
  jQuery("option[value='"+value+"']", this.$select)[0].selected=true;

  var $newLine = jQuery('<li oid="'+value+'"><span class="label">'+text+"</span></li>");
  $newLine.appendTo(jQuery(".selected", this.$wrapper)).css({opacity:0});
  $newLine.animate({opacity:1});
  if (this.isMultiple) {
    jQuery('<span class="delete"></span>').appendTo($newLine).click(function() {
      var $li=jQuery(this).parent();
      value = $li.attr('oid');
      jQuery("li[oid='"+value+"']").slideDown();
      jQuery("option[value='"+value+"']", this.$select)[0].selected=false;
      $li.slideUp().remove();
      if (jQuery(".selector", this.$wrapper).css('display')=='none' && this.maxItems && jQuery(".selected li", this.$wrapper).length < this.maxItems) {
        jQuery(".selector", this.$wrapper).fadeIn();
      }
    });
  }
  if (this.maxItems && jQuery(".selected li", this.$wrapper).length >= this.maxItems) {
    jQuery(".selector", this.$wrapper).fadeOut();
  }
}

MultiSelectPopup.prototype.initialize = function() {
  var self = this;
  this.$select=jQuery(this.element);
  this.isMultiple = this.$select.attr('multiple');

  // Wrapping du select
  this.$select.wrap('<div class="artools-select '+(this.isMultiple?'multiple':'simple')+'">');
  this.$wrapper = this.$select.parent();

  // Construction de sélecteur
  var output='';
  output+= '<div class="selector"><a class="ui-select" href="#">'+this.label+'</a><ul class="items">';
  output += '<ul class="items">';
  var currentLevel=0;
  for (var i=0; i < this.$select[0].options.length; i++) {
    var
      text= this.$select[0].options[i].text,
      value= this.$select[0].options[i].value,
      level=0;
    while (text.charAt(level)=='-') {
      level++;
    }
    text = text.substr(level);

    if (level > currentLevel) {
      output += '<ul class="level-'+level+'">';
    }
    if (level < currentLevel) {
      for (var j=0; j < currentLevel-level; j++) {
        output += "</li></ul>";
      }
    }
    if (level == currentLevel) {
      output += "</li>";
    }
    currentLevel=level;
    output+= '<li oid="'+value+'">';
    output += '<a';
    if (level<this.minSelectableLevel || level > this.maxSelectableLevel) {
      output += ' class="no-click"';
    }
    output += ' href="#">'+text+"</a>";
  }
  output += '</ul>';
  jQuery(this.element).parent().append('<ul class="selected"></ul>');
  jQuery(this.element).parent().append(output);

  // Ajout des clicks
  jQuery('.selector .ui-select', this.$wrapper).click(function() {
    var position = jQuery(this).offset();
    jQuery('.items', jQuery(this).parent()).menuPopup({
      x:position.left,
      y:position.top+jQuery(this).innerHeight()+3,
      width: jQuery(this).innerWidth(),
      align: 'left',
      cssClass: 'multiSelect',
      onClick: function(link) {
        self.itemSelection(link);
        return false;
      },
      onClose: function() {}
    });
    return false;
  });

  // Sélection des éléments déjà cochés
  for (i=0; i < this.$select[0].options.length; i++) {
    if (this.$select[0].options[i].selected) {
      var $item = jQuery("li[oid='"+this.$select[0].options[i].value+"']>a", this.$wrapper);
      self.itemSelection($item);
    }
  }

  this.$select.css({display:'none'});
}

Artools.jQueryPlugin('multiSelectPopup', MultiSelectPopup);


