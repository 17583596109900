/*
 * This file is part of "artools".
 *    Copyright 2009-2011, arNuméral
 *    Author : Yoran Brault
 *    eMail  : yoran.brault@bad_arnumeral.fr (remove bad_ before sending an email)
 *    Site   : http://www.arnumeral.fr
 *
 * "artools" is free software; you can redistribute it and/or
 * modify it under the terms of the GNU General Public License as
 * published by the Free Software Foundation; either version 2.1 of
 * the License, or (at your option) any later version.
 *
 * "artools" is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the GNU
 * General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with "artools"; if not, write to the Free
 * Software Foundation, Inc., 51 Franklin St, Fifth Floor, Boston, MA
 * 02110-1301 USA, or see the FSF site: http://www.fsf.org.
 */

var Tabs = function(){};
Tabs.prototype.initialize = function() {
  // Gestion des positions par défaut
  if (jQuery('.pane', this.element).length==0) return;
  var defaultPosition = jQuery('.pane', this.element)[0].id;
  if(this.element.id) {
    var cookiePosition = jQuery.cookie('tab'+this.element.id);
    if (cookiePosition) {
      defaultPosition = cookiePosition
    }
    var queryPosition = Artools.queryGet(this.element.id,'');
    if (queryPosition != '') {
      defaultPosition = queryPosition;
    }
  }

  // Activation des liens de sélection des onglets
  var self=this;
  jQuery(".tabset a", this.element).click(function() {
    var tokens = this.href.split('#');
    if(self.element.id) {
      jQuery.cookie('tab'+self.element.id, tokens[1]);
    }
    self.updatePanes(tokens[1]);
    return false;
  });

  // Mise à jour des onglets
  this.updatePanes(defaultPosition);
}

/**
 * Procédure de mise à jour de l'onglet Actif.
 */
Tabs.prototype.updatePanes = function(newIndex) {
  jQuery(".tabset>li", this.element).removeClass('active');
  jQuery(".pane", this.element).css('display', 'none');
  jQuery(".pane#"+newIndex, this.element).css('display', 'block');
  jQuery(".tabset a[href='#"+newIndex+"']").parent().addClass("active");
}

Artools.jQueryPlugin('tabs', Tabs);

