// Module
var Notification = (function() {
  // Constructeur
  function Notification(settings) {
    settings = settings || {};
    this.timeout = settings.timeout || 5000;
    this.initial = settings.initial || 1000;
  }

  Notification.prototype.initialize = function() {
    var self = this;
    var $item = jQuery(self.element);
    window.setTimeout(function() {
      $item.addClass('active');
      $item.click(function() {
        $item.removeClass('active');
      })
      window.setTimeout(function() {
        $item.removeClass('active');
      }, self.timeout);
    }, self.initial);
  }

  return Notification;
})();

Artools.jQueryPlugin('notification', Notification);


